<template>
  <card>
    <div class="custom-container">
      <card class="shadow-none" id="resultados-evaluado">
        <HXContentCard title="Volver A los Puntos de Entrega" :toBack="{ name: 'ListaEntregas' }">
        </HXContentCard>
        <!-- Botones -->
        <div class="col-12 col-md-6 col-lg-12">
          <CardTable :data="dataCards" />
        </div>

        <div class="col-12 col-md-6 col-lg-8" style="margin-top: 2%; margin-bottom: 2%;">
          <span class="card-title my-2 h2"><b>Coordenadas del conductor</b></span>
        </div>

        <!-- Tabla de resultados -->
        <div v-if="coordenadas.length > 0" class="table-responsive">
          <table id="datatable_resultadosEvaluacion" class="table table-bordered table-striped text-center">
            <thead>
              <tr>
                <th>Latitud Ubicación</th>
                <th>Longitud Ubicación</th>
                <th>Fecha y Hora</th>
                <th>Lugar de entrega</th>
                <th>Latitud Ubicación entrega</th>
                <th>Longitud Ubicación entrega</th>
                <th>Estado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in coordenadas" :key="index">
                <td>{{ item.latUbicacionActual }}</td>
                <td>{{ item.longUbicacionActual }}</td>
                <td>{{ item.fechaHoraActual }}</td>
                <td>{{ item.lugarEntrega }}</td>
                <td>{{ item.latUbicacionEntrega }}</td>
                <td>{{ item.longUbicacionEntrega }}</td>
                <td>{{ item.estadoCoordenadas }}</td>
                <td>
                  <!-- Botón para abrir Google Maps con las coordenadas del conductor y destino -->
                  <vs-button v-b-tooltip="{
                    title: `Ver en Google Maps`,
                    placement: 'top',
                    customClass: 'font_tooltip',
                  }" icon @click="abrirGoogleMaps(item)">
                    <i class="fas fa-map"></i> Ver en Google Maps
                  </vs-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <transition name="fade">
          <div v-if="coordenadas.length === 0" class="text-center p-5">
            <div class="no-results-container">
              <div class="emoji-container">
                <span class="emoji" role="img" aria-label="Mapa">🗺️</span>
                <span class="emoji" role="img" aria-label="Lupa">🔍</span>
                <span class="emoji" role="img" aria-label="Pensando">🤔</span>
              </div>
              <span class="h2 no-results-title">¡Oops! No hay resultados</span>
              <p class="no-results-message">Parece que no hay coordenadas registradas en este momento.</p>
              <button @click="volverAListaEntregas" class="refresh-button">
                <span class="refresh-icon" role="img" aria-label="Volver">↩️</span> Volver a Lista de Entregas
              </button>
            </div>
          </div>
        </transition>
      </card>
    </div>
  </card>
</template>

<script>
import CardTable from "../../../components/cardTable/CardTable.vue";

export default {
  name: "DetallePuntoEntrega",
  components: {
    CardTable,
  },
  data() {
    return {
      dataCards: [
        {
          title: "Número de coordenadas registradas hasta el momento",
          value: () => this.coordenadas.length,
          iconName: "map-marker-alt",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            this.dataTableInstance.search("").draw();
          },
          onDeactivate: () => {
            this.dataTableInstance.search("").draw();
          },
        },
      ],
      coordenadas: [],
      error: null,
    };
  },
  async created() {
    const puntoEntregaId = this.$route.params.id;
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    const empresaId = this.usuario.empresa.id;

    if (puntoEntregaId && empresaId) {
      await this.obtenerCoordenadasConductor(puntoEntregaId);
    } else {
      this.error = "No se proporcionaron parámetros válidos.";
    }
  },
  methods: {
    async obtenerCoordenadasConductor(puntoEntregaId) {
      try {
        const response = await this.$store.dispatch("hl_get", {
          path: `EntregasApp/GetCoordenadasConductorConPlantilla/${puntoEntregaId}`,
        });

        if (response.success) {
          this.coordenadas = response.data.map((item) => ({
            ...item,
            fechaHoraActual: this.formatearFecha(item.fechaHoraActual),
          }));
          console.log("Estos son los datos recibidos", this.coordenadas);
          if (this.coordenadas.length > 0) {
            this.inicializarDataTable();
          }
        } else {
          this.error = response.message || "No se pudieron obtener las coordenadas del conductor.";
        }
      } catch (error) {
        console.error("Error al obtener las coordenadas del conductor:", error);
        this.error = "Ocurrió un error al obtener las coordenadas del conductor.";
      }
    },

    formatearFecha(fechaISO) {
      const date = new Date(fechaISO);
      const opciones = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      };
      const fechaFormateada = new Intl.DateTimeFormat('es-ES', opciones).format(date);
      return fechaFormateada;
    },

    inicializarDataTable() {
      this.$nextTick(() => {
        if (window.$.fn.DataTable.isDataTable("#datatable_resultadosEvaluacion")) {
          window.$("#datatable_resultadosEvaluacion").DataTable().destroy();
        }
        window.$("#datatable_resultadosEvaluacion").DataTable({
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
          pageLength: 5,
        });
      });
    },

    actualizarDatos() {
      this.obtenerCoordenadasConductor(this.$route.params.id);
    },

    abrirGoogleMaps(item) {
      const latitudActual = item.latUbicacionActual;
      const longitudActual = item.longUbicacionActual;
      const latitudDestino = item.latUbicacionEntrega;
      const longitudDestino = item.longUbicacionEntrega;

      console.log("Coordenadas origen:", latitudActual, longitudActual);
      console.log("Coordenadas destino:", latitudDestino, longitudDestino);

      const url = `https://www.google.com/maps/dir/?api=1&origin=${latitudActual},${longitudActual}&destination=${latitudDestino},${longitudDestino}&travelmode=driving`;

      window.open(url, '_blank');
    },
    volverAListaEntregas() {
      this.$router.push({ name: 'ListaEntregas' });
    },
  },
};
</script>

<style scoped>
.emoji-container {
  font-size: 48px;
  margin-bottom: 20px;
}

.emoji {
  margin: 0 10px;
  display: inline-block;
  animation: bounce 2s infinite;
}

.no-results-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.no-results-message {
  font-size: 18px;
  margin-bottom: 30px;
}

.refresh-button {
  background-color: white;
  color: black;
  border: none;
  padding: 12px 24px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.refresh-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  background-color: #f0f0f0;
}

.refresh-icon {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 8px;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-10px);
  }
}
</style>